.about {
    width: 70%;
    margin: 100px auto;
    padding: 50px 0;

    @media screen and (max-width: 1200px) {
        width: 100%;
        padding: 50px 25px;
    }

    &__title {
        width: 100%;
        color: #517498;
        font-size: 25px;
        text-align: center;
        margin: 20px auto;
        letter-spacing: 3px;

        @media screen and (max-width: 800px) {
            width: 100%;
            padding: auto 20px;
        }
    }

    &__desc {
        width: 100%;
        color: #76aac8;
        font-size: 18px;
        text-align: justify;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        @media screen and (max-width: 1200px) {
            flex-direction: column;
        }

        &--image {
            margin: 0 60px 0 0;
            padding: 0 60px 0 0;
            border-right: 1px solid #517498;

            img {
                float: left;
                width: 200px;
                height: 200px;
                border-radius: 50%;
                margin: 20px;
            }

            @media screen and (max-width: 1200px) {
                border-right: none;
                display: flex;
                justify-content: center;
                align-items: center;
                clear: both;
                margin: 0;
                padding: 0;
            }
        }

        &--text {
            display: flex;
            justify-content: left;
            flex-direction: column;
            text-align: left;

            p {
                margin: 20px 0;
            }

            ul {
                margin: 15px 0 0 40px;
            }
        }

        &--email {
            display: flex;
            justify-content: start;
            align-items: center;
            margin-top: 15px;

            a {
                text-decoration: none;
                padding: 7px 17px;
                border-radius: 20px;
                background-color: #517498;
                color: black;
                transition: color 0.3s ease-in;

                &:hover {
                    color: white;
                }
            }
        }

        &--social {
            display: flex;
            justify-content: left;
            align-items: center;
            flex-direction: row;
            margin-top: 30px;

            .icon {
                margin: 12px;
                width: 50px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 100%;
                    height: auto;
                    transition: transform 0.1s linear;

                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
}
