.works {
    width: 90%;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    &__element {
        overflow: hidden;
        margin: 20px 30px;
        &--image {
            width: auto;
            height: 250px;
            cursor: pointer;
            transition: transform 0.3s linear;

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}

.null {
    color: #517498;
}
