.header {
    width: 100%;

    left: 0;
    z-index: 1000;
    padding: 20px 0;
    transition: transform 0.3s ease;

    &__nav {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 20px;

        &--link {
            margin: 0 20px;

            a {
                color: rgba(255, 255, 255, 0.8);
                text-decoration: none;
                letter-spacing: 3px;
            }
        }
    }
}

.absolute {
    position: absolute;
    transform: scale(0);
}

.fixed {
    position: fixed;
    transform: scale(1);
}
