.modalWorks {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -100%;
    background-color: #0e1216;
    z-index: 99999999;
    transition: left 0.7s ease;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #0e1216;
    }

    &::-webkit-scrollbar-thumb {
        background: #517498;
    }

    &--opened {
        left: 0;
    }

    &--closed {
        left: -100%;
    }

    .modalClose {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 30px;
        color: white;
        cursor: pointer;
    }
}

.modalContent {
    width: 90%;
    margin: 50px auto 20px auto;

    &__title {
        color: #517498;
        font-size: 22px;
        width: 100%;
        text-align: center;
        margin: 50px auto;
    }

    &__desc {
        width: 100%;
        margin: 0 auto;
        overflow: hidden;

        .video {
            width: 50%;
            height: 600px;
            margin: 10px auto 60px auto;
            display: block;

            @media screen and (max-width: 800px) {
                height: 400px;
            }
        }

        img {
            display: block;
            margin: 0 auto;
            max-width: 50%;
            max-height: 80vh;
            height: auto;
            margin: 20px auto;
        }
    }
}
