.parallax__wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    scroll-behavior: smooth;

    @media screen and (max-width: 1300px) {
        height: 20vh;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        display: block;

        @media screen and (max-width: 1300px) {
            display: none;
        }
    }
}

.scroll {
    width: 32px;
    height: 32px;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%) rotate(90deg);
    z-index: 9999999;
    animation: scroll-down 1.5s linear infinite;

    img {
        filter: invert(100%);
    }
}

@keyframes scroll-down {
    from {
        bottom: 40px;
    }
    to {
        bottom: 15px;
        transform: roate(90deg);
        opacity: 0;
    }
}
