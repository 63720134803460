@font-face {
    font-family: galyon;
    src: url('./Assets/fonts/galyon.otf');
}

*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background-color: #0e1216;
    font-family: galyon;
}
