.categoryContainer {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 15px;
    flex-wrap: wrap;

    a {
        width: 400px;
        margin: 0 20px;
    }

    @media screen and (max-width: 400px) {
        width: 100%;

        a {
            width: 100%;
            margin: 0 auto;
        }
    }
}

.categoryTitle {
    width: 100%;
    display: flex;
    font-size: 25px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px 0;
    color: #517498;
    letter-spacing: 3px;
}
