.categoryWrapper {
    position: relative;
    height: 200px;
    overflow: hidden;
    margin: 20px auto;

    @media screen and (max-width: 600px) {
        width: 100%;
    }

    &:hover {
        .categoryName {
            transform: scale(1);
        }
    }

    &--img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        pointer-events: none;
    }

    .categoryName {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        color: #517498;
        letter-spacing: 3px;
        transform: scale(0);
        z-index: 99;
        transition: transform 0.3s ease;
        background-color: rgba(14, 18, 22);
    }
}
