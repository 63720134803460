.coop {
    width: 90%;
    margin: 0px auto 20px auto;
    overflow: hidden;
    position: relative;
    min-height: 100px;

    &__title {
        color: #517498;
        font-size: 25px;
        text-align: center;
        margin: 20px auto;
        letter-spacing: 3px;
    }

    &__elements {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        a {
            margin: 10px 30px;
            display: block;

            img {
                height: 50px;
                width: auto;
            }
        }
    }

    @media screen and (max-width: 1300px) {
        width: 100%;
    }
}
